import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../Actions/index";
import Filter from "../dashboard/Filter";
import SideFilter from "../dashboard/SideFilter";

let isUserLoggedIn = JSON.parse(localStorage.getItem("ecrypt_data"))
  ? JSON.parse(localStorage.getItem("ecrypt_data"))
  : "";

export class SettingsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeRightSidebar() {
    document.querySelector(".right-sidebar").classList.remove("open");
  }

  callAllApis = (data) => {
    this.props.getAverageCount(data);
    this.props.getStoriesCount(data);
    this.props.getPromptsCount(data);
    this.props.getFavStories(data);
    this.props.getFavStoriesList(data);
    this.props.getTopStories(data);
  };

  userData = (data) => {
    this.props.getStoriesRead(data);
    this.props.getTimeSpentInApp(data);
    this.props.getPromptsRead(data);
    this.props.getFavStoriesTable(data);
    this.props.getTopStoriesTable(data);
    this.props.getAllGoals(data.org_code[0]);
  };

  handleChange = (selectedOption) => {
    this.props.FilterByOrgCode(selectedOption.org_code, selectedOption.label);
    let data = {
      org_code: selectedOption.org_code,
      age: this.props.filter_age,
      date: this.props.filter_date,
    };
    this.callAllApis(data);
    this.userData(data);
  };

  onDataTypeChange = (value) => {
    this.props.summaryData(value);
    let data = {
      org_code: this.props.filter_code
        ? this.props.filter_code
        : isUserLoggedIn.data.length > 0
        ? isUserLoggedIn.data[0].org_code
        : "",
      age: this.props.filter_age,
      date: this.props.filter_date,
    };
    if (
      value === "user_data" &&
      !this.props.time_spent_in_app &&
      !this.props.stories_read &&
      !this.props.prompts_read &&
      !this.props.top_stories_table
    ) {
      this.userData(data);
    }
  };

  onChageByAge = (selectedOption) => {
    this.props.FilterByAge(selectedOption.age, this.props.nick_name);
    let data = {
      org_code: this.props.filter_code
        ? this.props.filter_code
        : isUserLoggedIn.data[0].org_code,
      age: selectedOption.age,
      date: this.props.filter_date,
    };
    this.callAllApis(data);
    this.userData(data);
  };

  onChageByDate = (value) => {
    this.props.FilterByDate(value, this.props.nick_name);
    let data = {
      org_code: this.props.filter_code
        ? this.props.filter_code
        : isUserLoggedIn.data[0].org_code,
      age: this.props.filter_age,
      date: value,
    };

    this.callAllApis(data);
    this.userData(data);
  };

  updateOrgCode = (old_code, updated_code) => {
    let form_data = {
      username: isUserLoggedIn.username,
      org_code: old_code,
      nick_name: updated_code,
    };
    this.props.updateOrgCode(form_data);
  };

  render() {
    return (
      <div>
        <div id="right-sidebar" className="settings-panel right-sidebar">
          <i
            className="settings-close mdi mdi-close"
            onClick={this.closeRightSidebar}
          ></i>
          <Tabs
            defaultActiveKey="Filter"
            className="bg-gradient-primary"
            id="uncontrolled-tab-example"
          >
            <Tab eventKey="Filter" title="FILTER" className="test-tab">
              <SideFilter
                {...this.props}
                onDataTypeChange={(e) => {
                  this.onDataTypeChange(e);
                }}
                onChageByDate={(e) => {
                  this.onChageByDate(e);
                }}
                onChageByAge={(e) => {
                  this.onChageByAge(e);
                }}
                handleChange={(e) => {
                  this.handleChange(e);
                }}
                updateOrgCode={(old_code, new_code) => {
                  this.updateOrgCode(old_code, new_code);
                }}
              />
            </Tab>

            {/* <Tab eventKey="CHATS" title="By Age">
              <div></div>
            </Tab> */}
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.Common.loader,
    avg_count: state.AvgCount.avg_count,
    stories_count: state.StoriesCount.stories_count,
    prompts_count: state.PromptsCount.prompts_count,
    top_stories: state.TopStories.top_stories.top_stories,
    stories_read: state.StoriesRead.stories_read.stories_read,
    time_spent_in_app: state.TimeSpentInApp.time_spent_in_app.time_spent_in_app,
    prompts_read: state.PromptsRead.prompts_read.prompts_read,
    top_stories_table: state.TopStoriesTable.top_stories_table.top_stories_read,
    favourite: state.Favourites.favourite.favourites,
    favourite_stories_read:
      state.FavReadTable.fav_stories_table.favourite_stories_read,
    favourite_list: state.FavList.fav_list.favourite_stories,
    data_type: state.Filter.data_type,
    filter_age: state.ApiFilter.filter_age,
    filter_date: state.ApiFilter.filter_date,
    filter_code: state.ApiFilter.filter_code,
    nick_name: state.ApiFilter.nick_name,
    profile: state.Profile.details,
    getorg: state.GetOrg,
    all_goals: state.GetAllGoals.all_goals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetHcode: () => {
      dispatch(actions.GetHcode());
    },

    // genral Fucntion
    getAverageCount: (data) => {
      dispatch(actions.getAverageCount(data));
    },
    getStoriesCount: (data) => {
      dispatch(actions.getStoriesCount(data));
    },
    getPromptsCount: (data) => {
      dispatch(actions.getPromptsCount(data));
    },
    getFavStories: (data) => {
      dispatch(actions.getFavStories(data));
    },
    getFavStoriesList: (data) => {
      dispatch(actions.getFavStoriesList(data));
    },
    getTopStories: (data) => {
      dispatch(actions.getTopStories(data));
    },

    //Table Fucntion
    getStoriesRead: (data) => {
      dispatch(actions.getStoriesRead(data));
    },
    getTimeSpentInApp: (data) => {
      dispatch(actions.getTimeSpentInApp(data));
    },
    getPromptsRead: (data) => {
      dispatch(actions.getPromptsRead(data));
    },
    getFavStoriesTable: (data) => {
      dispatch(actions.getFavStoriesTable(data));
    },
    getTopStoriesTable: (data) => {
      dispatch(actions.getTopStoriesTable(data));
    },
    getAllGoals: (org_code) => {
      dispatch(actions.getAllGoals(org_code));
    },
    updateOrgCode: (data) => {
      dispatch(actions.updateOrgCode(data));
    },

    //Filter Fucntion
    summaryData: (value) => {
      dispatch(actions.summaryData(value));
    },
    FilterByAge: (value, nick_name) => {
      dispatch(actions.FilterByAge(value, nick_name));
    },
    FilterByDate: (value, nick_name) => {
      dispatch(actions.FilterByDate(value, nick_name));
    },
    FilterByOrgCode: (value, nick_name) => {
      dispatch(actions.FilterByOrgCode(value, nick_name));
    },
    stopLoader: () => {
      dispatch(actions.stopLoader());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel);
