import { DATA_TYPE } from "../Actions/types";
const INITIAL_STATE = {
  data_type: "summary_data",
};
const PromptsCountReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DATA_TYPE:
      return { ...state, ...INITIAL_STATE, data_type: action.value };
    default:
      return state;
  }
};

export default PromptsCountReducers;
