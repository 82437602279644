import {
  FILTER_AGE_TYPE,
  FILTER_DATE_TYPE,
  FILTER_ORG_CODE_TYPE,
} from "../Actions/types";

const INITIAL_STATE = {
  filter_age: "all_ages",
  filter_code: "all",
  nick_name: "All",
  filter_date: "all_time",
};
const ApiFilterReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FILTER_AGE_TYPE:
      return {
        ...state,
        filter_age: action.value,
        nick_name: action.nick_name,
      };
    case FILTER_DATE_TYPE:
      return {
        ...state,
        filter_date: action.value,
        nick_name: action.nick_name,
      };
    case FILTER_ORG_CODE_TYPE:
      return {
        ...state,
        filter_code: action.value,
        nick_name: action.nick_name,
      };
    default:
      return state;
  }
};

export default ApiFilterReducers;
