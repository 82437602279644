import { FAVOURITES_LIST } from "../Actions/types";
const INITIAL_STATE = {
  fav_list: {},
};
const FavListReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FAVOURITES_LIST:
      return { ...state, ...INITIAL_STATE, fav_list: action.data };

    default:
      return state;
  }
};

export default FavListReducers;
