import { PROMPTS_READ } from "../Actions/types";

const INITIAL_STATE = { prompts_read: {} };

const PromptsReadReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROMPTS_READ:
      return { ...state, ...INITIAL_STATE, prompts_read: action.data };

    default:
      return state;
  }
};
export default PromptsReadReducers;
