import React, { Component } from "react";
import Select from "react-select";
import _ from "lodash";

const age_data = [
  { age: "all_ages", label: "All Ages" },
  { age: "baby", label: "Baby: 6-18 months" },
  { age: "toddler", label: "Toddler: 12-36 months" },
  { age: "preschooler", label: "Prereader: 3+ years " },
];

class SideFilter extends Component {
  constructor(props) {
    super(props);
  }
  state = {};

  style = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#F45C40",
      borderWidth: 1,
      "&:hover": {
        border: "1px solid #F45C40",
      },
    }),
  };
  RenderCode = () => {
    let OrgCode = [];
    if (Object.keys(this.props.getorg.org_code).length > 0) {
      OrgCode.push({ org_code: "all", label: "All" });
      _.map(this.props.getorg.org_code, (i) => {
        OrgCode.push({
          org_code: i.org_code,
          label: i.nick_name ? i.nick_name : i.org_code,
        });
      });

      return (
        <Select
          defaultValue={OrgCode[0]}
          onChange={this.props.handleChange}
          getOptionValue={(option) => option.org_code}
          options={OrgCode}
          styles={this.style}
          theme={(theme) => ({
            ...theme,

            // borderRadius: 0,
            colors: {
              ...theme.colors,
              text: "#0f2027",
              primary25: "#e5e5e5",
            },
          })}
          menuPlacement="top"
        />
      );
    }
  };
  render() {
    return (
      <>
        <div className="card sideFilterBackGroud">
          <div className=" card-body px-0 pt-1">
            <form className="">
              {/* <p className="card-description text-RACPrimary mb-1 font-weight-bold">
                {" "}
                By Data{" "}
              </p>
              <div className="">
                <div className="form-check pr-2">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      name="DataType"
                      className={`form-check-input ${
                        this.props.data_type === "summary_data" ? `active` : ""
                      }`}
                      //   name="optionsRadios"
                      id="optionsRadios1"
                      value=""
                      defaultChecked={
                        this.props.data_type === "summary_data" ? true : false
                      }
                      onClick={(e) => {
                        this.props.onDataTypeChange("overview");
                      }}
                    />
                    <i className="input-helper"></i>
                    Aggregate
                  </label>
                </div>
                <div className="form-check pr-5">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="DataType"
                      id="optionsRadios1"
                      value=""
                      defaultChecked={
                        this.props.data_type === "user_data" ? true : false
                      }
                      onClick={(e) => {
                        this.props.onDataTypeChange("user_data");
                      }}
                    />
                    <i className="input-helper"></i>
                    Users
                  </label>
                </div>
              </div> */}
              <p className="card-description text-RACPrimary mb-1 font-weight-bold">
                By Time{" "}
              </p>
              <div className="">
                <div className="form-check pr-2">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className={`form-check-input ${
                        this.props.filter_date === "filter_date" ? `active` : ""
                      }`}
                      name="DateFilter"
                      id="optionsRadios1"
                      value=""
                      defaultChecked={
                        this.props.filter_date === "past_month" ? true : false
                      }
                      onClick={(e) => {
                        this.props.onChageByDate("past_month");
                      }}
                    />
                    <i className="input-helper"></i>
                    Past Month
                  </label>
                </div>
                <div className="form-check pr-2">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="DateFilter"
                      id="optionsRadios1"
                      value=""
                      defaultChecked={
                        this.props.filter_date === "past_year" ? true : false
                      }
                      onClick={(e) => {
                        this.props.onChageByDate("past_year");
                      }}
                    />
                    <i className="input-helper"></i>
                    Past Year
                  </label>
                </div>
                <div className="form-check pr-5">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="DateFilter"
                      id="optionsRadios1"
                      value=""
                      defaultChecked={
                        this.props.filter_date === "all_time" ? true : false
                      }
                      onClick={(e) => {
                        this.props.onChageByDate("all_time");
                      }}
                    />
                    <i className="input-helper"></i>
                    All Time
                  </label>
                </div>
              </div>
              {/*  */}
              <p className="card-description text-RACPrimary pt-2 mb-1 font-weight-bold">
                By Age
              </p>
              <div className="">
                {/* <Select
                  // defaultValue={{ label: organization[0].nick_name, value: organization[0].nick_name }}
                  onChange={this.props.onChageByAge}
                  options={age_data}
                  getOptionValue={(option) => option.age}
                  styles={this.style}
                  menuPlacement="top"
                /> */}

                {age_data.map((item, index) => {
                  return (
                    <div className="form-check pr-2" key={index}>
                      <label className="form-check-label">
                        <input
                          key={index}
                          type="radio"
                          className={`form-check-input ${
                            this.props.filter_age === "all_ages" ? `active` : ""
                          }`}
                          name="ageFilter"
                          id="optionsRadios"
                          value=""
                          defaultChecked={
                            this.props.filter_age === `${item.age}`
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            this.props.onChageByAge({
                              age: item.age,
                              label: item.label,
                            });
                          }}
                        />
                        <i className="input-helper"></i>
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>

              <p className="card-description text-RACPrimary pt-2 mb-1 font-weight-bold">
                By Org Code
              </p>
              <div className="">{this.RenderCode()}</div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default SideFilter;
