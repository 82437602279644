import {
  UPDATE_PROFILE_SUCCESS,
  CLOSE_PROFILE_MESSAGE,
  UPDATE_PROFILE_VALIDATE,
} from "../Actions/types";
const INITIAL_STATE = {
  details: {},
  err_msg: "",
};
const ProfileUpdateReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, ...INITIAL_STATE, details: action.data };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, ...INITIAL_STATE, details: action.data };
    case UPDATE_PROFILE_VALIDATE:
      return { ...state, ...INITIAL_STATE, err_msg: action.data };
    case CLOSE_PROFILE_MESSAGE:
      return { ...state, ...INITIAL_STATE, details: action.data };

    default:
      return state;
  }
};

export default ProfileUpdateReducers;
