import { AVERAGE_COUNT } from "../Actions/types";
const INITIAL_STATE = {
  avg_count: {},
};
const AverageReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AVERAGE_COUNT:
      return { ...state, ...INITIAL_STATE, avg_count: action.data };
    default:
      return state;
  }
};

export default AverageReducers;
