import { UPDATE_ORG_CODE } from "../Actions/types";

const INITIAL_STATE = {};
const OrgCodeUpdateReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ORG_CODE:
      return { ...state, data: action.data.organization };

    default:
      return state;
  }
};

export default OrgCodeUpdateReducers;
