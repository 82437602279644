import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../Actions/index";
import SideFilter from "../dashboard/SideFilter";

const Rac_logo = require("../../assets/images/rac_logo.png");

let isUserLoggedIn = JSON.parse(localStorage.getItem("ecrypt_data"))
  ? JSON.parse(localStorage.getItem("ecrypt_data"))
  : "";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  callAllApis = (data) => {
    this.props.getAverageCount(data);
    this.props.getStoriesCount(data);
    this.props.getPromptsCount(data);
    this.props.getFavStories(data);
    this.props.getFavStoriesList(data);
    this.props.getTopStories(data);
  };

  userData = (data) => {
    this.props.getStoriesRead(data);
    this.props.getTimeSpentInApp(data);
    this.props.getPromptsRead(data);
    this.props.getFavStoriesTable(data);
    this.props.getTopStoriesTable(data);
    this.props.getAllGoals(data.org_code[0]);
  };

  handleChange = (selectedOption) => {
    this.props.FilterByOrgCode(selectedOption.org_code, selectedOption.label);
    let data = {
      org_code: selectedOption.org_code,
      age: this.props.filter_age,
      date: this.props.filter_date,
    };
    this.callAllApis(data);
    this.userData(data);
  };

  onDataTypeChange = (value) => {
    this.props.summaryData(value);
    let data = {
      org_code: this.props.filter_code
        ? this.props.filter_code
        : isUserLoggedIn.data.length > 0
        ? isUserLoggedIn.data[0].org_code
        : "",
      age: this.props.filter_age,
      date: this.props.filter_date,
    };
    if (
      value === "user_data" &&
      !this.props.time_spent_in_app &&
      !this.props.stories_read &&
      !this.props.prompts_read &&
      !this.props.top_stories_table
    ) {
      this.userData(data);
    }
  };

  onChageByAge = (selectedOption) => {
    this.props.FilterByAge(selectedOption.age, this.props.nick_name);
    let data = {
      org_code: this.props.filter_code
        ? this.props.filter_code
        : isUserLoggedIn.data[0].org_code,
      age: selectedOption.age,
      date: this.props.filter_date,
    };
    this.callAllApis(data);
    this.userData(data);
  };

  onChageByDate = (value) => {
    this.props.FilterByDate(value, this.props.nick_name);
    let data = {
      org_code: this.props.filter_code
        ? this.props.filter_code
        : isUserLoggedIn.data[0].org_code,
      age: this.props.filter_age,
      date: value,
    };

    this.callAllApis(data);
    this.userData(data);
  };

  updateOrgCode = (old_code, updated_code) => {
    let form_data = {
      username: isUserLoggedIn.username,
      org_code: old_code,
      nick_name: updated_code,
    };
    this.props.updateOrgCode(form_data);
  };

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          {/* <li className="d-flex justify-content-center align-items-center nav-item">
            <div className="row px-1 flex-inline align-items-center justify-content-center my-3 bg-success rounded px-3 py-1 nav-link">
              <span className="font-weight-bold text-dark menu-title">
                {localStorage.getItem("user")
                  ? localStorage.getItem("user").toString().slice(1, -1)
                  : ""}
              </span>
              <i className="mdi mdi-checkbox-marked-circle text-dark nav-profile-badge ml-1 "></i>
            </div>
            <br />
          </li> */}
          <li
            className={
              this.isPathActive("/dashboard")
                ? "shadow-lg nav-item active"
                : "nav-item"
            }
            // onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">
                <Trans>SUMMARY DATA</Trans>
              </span>
              <i className="mdi mdi-chart-pie menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/userdata") ? "nav-item active" : "nav-item"
            }
            // onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <Link className="nav-link" to="/userdata">
              <span className="menu-title">
                <Trans>USER DATA</Trans>
              </span>
              <i className="mdi mdi-table-large menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/codes") ? "nav-item active" : "nav-item"
            }
            // onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <Link className="nav-link" to="/codes">
              <span className="menu-title">
                <Trans>ADD USERS</Trans>
              </span>
              <i className="mdi mdi-account-multiple-plus menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/your-organization-codes")
                ? "nav-item active"
                : "nav-item"
            }
            // onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <Link className="nav-link" to="/your-organization-codes">
              <span className="menu-title">
                <Trans>YOUR ORG CODES</Trans>
              </span>
              <i className="mdi mdi-alphabetical-variant menu-icon"></i>
            </Link>
          </li>

          <li className="nav-item mt-5">
            <div
              className={
                this.state.basicUiMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("basicUiMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>FILTERS</Trans>
              </span>
              {/* <i className="menu-arrow text-dark"></i> */}
              <i className="mdi mdi-filter menu-icon px-0 text-dark"></i>
            </div>
            {/* <Collapse in={this.state.basicUiMenuOpen}> */}

            <SideFilter
              {...this.props}
              onDataTypeChange={(e) => {
                this.onDataTypeChange(e);
              }}
              onChageByDate={(e) => {
                this.onChageByDate(e);
              }}
              onChageByAge={(e) => {
                this.onChageByAge(e);
              }}
              handleChange={(e) => {
                this.handleChange(e);
              }}
              updateOrgCode={(old_code, new_code) => {
                this.updateOrgCode(old_code, new_code);
              }}
            />

            {/* </Collapse> */}
          </li>
          {/* Form Elements */}
          {/* <li
            className={
              this.isPathActive("/form-elements")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                this.state.formElementsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("formElementsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Form Elements</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            </div>
            <Collapse in={this.state.formElementsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/form-elements/basic-elements")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/form-elements/basic-elements"
                  >
                    <Trans>Basic Elements</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* Tables Here */}
          {/* <li
            className={
              this.isPathActive("/tables") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.tablesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("tablesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Tables Analytics</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-table-large menu-icon"></i>
            </div>
            <Collapse in={this.state.tablesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      this.isPathActive("/userdata")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/userdata"
                  >
                    <Trans>Tables Analytics</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* Icons */}
          {/* <li
            className={
              this.isPathActive("/icons") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.iconsMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("iconsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Icons</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-contacts menu-icon"></i>
            </div>
            <Collapse in={this.state.iconsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/mdi")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/mdi"
                  >
                    <Trans>Material</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* charts */}
          {/* <li
            className={
              this.isPathActive("/charts") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.chartsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("chartsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Charts</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-chart-bar menu-icon"></i>
            </div>
            <Collapse in={this.state.chartsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/charts/chart-js")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/charts/chart-js"
                  >
                    <Trans>Chart Js</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* User Pages */}
          {/* <li
            className={
              this.isPathActive("/user-pages") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.userPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("userPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>User Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-lock menu-icon"></i>
            </div>
            <Collapse in={this.state.userPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/auth/login")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/auth/login"
                  >
                    <Trans>Login</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/auth/register")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/auth/register"
                  >
                    <Trans>Register</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/user-pages/lockscreen")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/user-pages/lockscreen"
                  >
                    <Trans>Lockscreen</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* Error Pages */}
          {/* <li
            className={
              this.isPathActive("/error-pages") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.errorPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("errorPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Error Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-security menu-icon"></i>
            </div>
            <Collapse in={this.state.errorPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/error-pages/error-404")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-404"
                  >
                    404
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/error-pages/error-500")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/error-pages/error-500"
                  >
                    500
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* Blank Pages */}
          {/* <li
            className={
              this.isPathActive("/general-pages")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                this.state.generalPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("generalPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>General Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-medical-bag menu-icon"></i>
            </div>
            <Collapse in={this.state.generalPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/general-pages/blank-page")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/general-pages/blank-page"
                  >
                    <Trans>Blank Page</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li> */}
          {/* Documnetation */}

          {/* <li className="nav-item">
            <a
              className="nav-link"
              href="http://bootstrapdash.com/demo/purple-react-free/documentation/documentation.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="menu-title">
                <Trans>Documentation</Trans>
              </span>
              <i className="mdi mdi-file-document-box menu-icon"></i>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

// export default withRouter(Sidebar);

const mapStateToProps = (state) => {
  return {
    loader: state.Common.loader,
    avg_count: state.AvgCount.avg_count,
    stories_count: state.StoriesCount.stories_count,
    prompts_count: state.PromptsCount.prompts_count,
    top_stories: state.TopStories.top_stories.top_stories,
    stories_read: state.StoriesRead.stories_read.stories_read,
    time_spent_in_app: state.TimeSpentInApp.time_spent_in_app.time_spent_in_app,
    prompts_read: state.PromptsRead.prompts_read.prompts_read,
    top_stories_table: state.TopStoriesTable.top_stories_table.top_stories_read,
    favourite: state.Favourites.favourite.favourites,
    favourite_stories_read:
      state.FavReadTable.fav_stories_table.favourite_stories_read,
    favourite_list: state.FavList.fav_list.favourite_stories,
    data_type: state.Filter.data_type,
    filter_age: state.ApiFilter.filter_age,
    filter_date: state.ApiFilter.filter_date,
    filter_code: state.ApiFilter.filter_code,
    nick_name: state.ApiFilter.nick_name,
    profile: state.Profile.details,
    getorg: state.GetOrg,
    all_goals: state.GetAllGoals.all_goals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetHcode: () => {
      dispatch(actions.GetHcode());
    },

    // genral Fucntion
    getAverageCount: (data) => {
      dispatch(actions.getAverageCount(data));
    },
    getStoriesCount: (data) => {
      dispatch(actions.getStoriesCount(data));
    },
    getPromptsCount: (data) => {
      dispatch(actions.getPromptsCount(data));
    },
    getFavStories: (data) => {
      dispatch(actions.getFavStories(data));
    },
    getFavStoriesList: (data) => {
      dispatch(actions.getFavStoriesList(data));
    },
    getTopStories: (data) => {
      dispatch(actions.getTopStories(data));
    },

    //Table Fucntion
    getStoriesRead: (data) => {
      dispatch(actions.getStoriesRead(data));
    },
    getTimeSpentInApp: (data) => {
      dispatch(actions.getTimeSpentInApp(data));
    },
    getPromptsRead: (data) => {
      dispatch(actions.getPromptsRead(data));
    },
    getFavStoriesTable: (data) => {
      dispatch(actions.getFavStoriesTable(data));
    },
    getTopStoriesTable: (data) => {
      dispatch(actions.getTopStoriesTable(data));
    },
    getAllGoals: (org_code) => {
      dispatch(actions.getAllGoals(org_code));
    },
    updateOrgCode: (data) => {
      dispatch(actions.updateOrgCode(data));
    },

    //Filter Fucntion
    summaryData: (value) => {
      dispatch(actions.summaryData(value));
    },
    FilterByAge: (value, nick_name) => {
      dispatch(actions.FilterByAge(value, nick_name));
    },
    FilterByDate: (value, nick_name) => {
      dispatch(actions.FilterByDate(value, nick_name));
    },
    FilterByOrgCode: (value, nick_name) => {
      dispatch(actions.FilterByOrgCode(value, nick_name));
    },
    stopLoader: () => {
      dispatch(actions.stopLoader());
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
// export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel);
