import { GET_ALL_GOALS } from "../Actions/types";
const INITIAL_STATE = {
  all_goals: {},
};
const GetAllGoalsReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_GOALS:
      return { ...state, ...INITIAL_STATE, all_goals: action.data };
    default:
      return state;
  }
};

export default GetAllGoalsReducers;
