import { STORIES } from "../Actions/types";
const INITIAL_STATE = {
  stories_count: {},
};
const StoriesReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORIES:
      return { ...state, ...INITIAL_STATE, stories_count: action.data };
    default:
      return state;
  }
};

export default StoriesReducers;
