import React, { Component } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import _ from "lodash";

const age_data = [
  { age: "all_ages", label: "All Ages" },
  { age: "baby", label: "Baby: 6-18 months" },
  { age: "toddler", label: "Toddler: 12-36 months" },
  { age: "preschooler", label: "Prereader: 3+ years " },
];

class Filter extends Component {
  constructor(props) {
    super(props);
  }
  state = {};

  style = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#F45C40",
      borderWidth: 1,
      "&:hover": {
        border: "1px solid #F45C40",
      },
    }),
  };
  RenderCode = () => {
    let OrgCode = [];
    if (Object.keys(this.props.getorg.org_code).length > 0) {
      OrgCode.push({ org_code: "all", label: "All" });
      _.map(this.props.getorg.org_code, (i) => {
        OrgCode.push({
          org_code: i.org_code,
          label: i.nick_name ? i.nick_name : i.org_code,
        });
      });

      return (
        <Select
          defaultValue={{
            label: this.props.getorg.org_code[0].nick_name
              ? this.props.getorg.org_code[0].nick_name
              : this.props.getorg.org_code[0].org_code,
            value: this.props.getorg.org_code[0].org_code,
          }}
          getOptionValue={(option) => option.org_code}
          onChange={this.props.handleChange}
          options={OrgCode}
          styles={this.style}
          placeholder="Select Org Code"
        />
      );
    }
  };
  render() {
    return (
      <>
        <div className="form-inline d-flex justify-content-between align-items-center grid-margin">
          <label className="sr-only" htmlFor="inlineFormInputName2">
            Filter
          </label>
          {/*  */}
          <div className="col-md-2 col-xl-3 col-md-8 col-lg-8 col-xxl-3 p-0">
            <p className="card-description text-RACPrimary mb-1 font-weight-bold">
              FILTER BY DATA
            </p>
            <div className=" row  mw-100 col-md-2 col-xl-3 col-md-8 col-lg-8 col-xxl-3">
              <div className="form-check pr-2">
                <label className="form-check-label ">
                  <input
                    type="radio"
                    name="DataType"
                    className={`form-check-input ${
                      this.props.data_type === "summary_data" ? `active` : ""
                    }`}
                    //   name="optionsRadios"
                    id="optionsRadios1"
                    value=""
                    defaultChecked={
                      this.props.data_type === "summary_data" ? true : false
                    }
                    onClick={(e) => {
                      this.props.onDataTypeChange("overview");
                    }}
                  />
                  <i className="input-helper"></i>
                  Aggregate
                </label>
              </div>
              <div className="form-check pr-5">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="DataType"
                    id="optionsRadios1"
                    value=""
                    defaultChecked={
                      this.props.data_type === "user_data" ? true : false
                    }
                    onClick={(e) => {
                      this.props.onDataTypeChange("user_data");
                    }}
                  />
                  <i className="input-helper"></i>
                  Users
                </label>
              </div>
            </div>
          </div>

          {/*  */}
          <div className="col-md-3 col-xl-4 col-md-8 col-lg-8 col-xxl-3 p-0">
            <p className="card-description text-RACPrimary mb-1 font-weight-bold">
              FILTER BY DATE
            </p>
            <div className="row mw-100 col-md-4 col-xl-4 col-md-8 col-lg-8 col-xxl-3">
              <div className="form-check pr-2">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className={`form-check-input ${
                      this.props.filter_date === "filter_date" ? `active` : ""
                    }`}
                    name="DateFilter"
                    id="optionsRadios1"
                    value=""
                    defaultChecked={
                      this.props.filter_date === "past_month" ? true : false
                    }
                    onClick={(e) => {
                      this.props.onChageByDate("past_month");
                    }}
                  />
                  <i className="input-helper"></i>
                  Past Month
                </label>
              </div>
              <div className="form-check pr-2">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="DateFilter"
                    id="optionsRadios1"
                    value=""
                    defaultChecked={
                      this.props.filter_date === "past_year" ? true : false
                    }
                    onClick={(e) => {
                      this.props.onChageByDate("past_year");
                    }}
                  />
                  <i className="input-helper"></i>
                  Past Year
                </label>
              </div>
              <div className="form-check pr-5">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="DateFilter"
                    id="optionsRadios1"
                    value=""
                    defaultChecked={
                      this.props.filter_date === "all_time" ? true : false
                    }
                    onClick={(e) => {
                      this.props.onChageByDate("all_time");
                    }}
                  />
                  <i className="input-helper"></i>
                  All Time
                </label>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="col-md-2 col-xl-2 m-1 col-md-8 col-lg-8 col-xxl-2 p-0">
            <p className="card-description text-RACPrimary mb-1 font-weight-bold">
              FILTER BY AGE
            </p>

            {/* <p class="text-left mb-0">Filter Age</p> */}
            <div className="">
              <Select
                // defaultValue={{ label: organization[0].nick_name, value: organization[0].nick_name }}
                onChange={this.props.onChageByAge}
                options={age_data}
                getOptionValue={(option) => option.age}
                styles={this.style}
                placeholder="Select Age"
              />
            </div>
          </div>
          {/*  */}
          <div className="col-md-2 col-xl-2 m-1 col-md-8 col-lg-8 col-xxl-2 p-0">
            <p className="card-description text-RACPrimary mb-1 font-weight-bold">
              FILTER BY ORG CODE
            </p>

            {/* <p class="text-left mb-0">Filter organization code</p> */}
            <div className="">{this.RenderCode()}</div>
          </div>
        </div>
      </>
    );
  }
}

export default Filter;
