import { LOGGED_FAILED, LOGGED_SUCCESSFULLY } from "../Actions/types";

const INITIAL_STATE = {
  failed: {},
  success: {},
};
const LoginReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGED_FAILED:
      return { ...state, ...INITIAL_STATE, failed: action.data };
    case LOGGED_SUCCESSFULLY:
      return { ...state, ...INITIAL_STATE, success: action.data };

    default:
      return state;
  }
};

export default LoginReducers;
