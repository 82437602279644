import { TOP_STORIES } from "../Actions/types";
const INITIAL_STATE = {
  top_stories: {},
};
const GetTopStories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOP_STORIES:
      return { ...state, ...INITIAL_STATE, top_stories: action.data };
    default:
      return state;
  }
};

export default GetTopStories;
