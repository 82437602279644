import { GET_ORG_CODE } from "../Actions/types";

const INITIAL_STATE = {
  org_code: {},
};
const OrgCodeReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORG_CODE:
      return { ...state, ...INITIAL_STATE, org_code: action.data };
    default:
      return state;
  }
};

export default OrgCodeReducers;
