export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const LOGGED_FAILED = "LOGGED_FAILED";
export const LOGGED_SUCCESSFULLY = "LOGGED_SUCCESSFULLY";

export const GET_ORG_CODE = "GET_ORG_CODE";

export const AVERAGE_COUNT = "AVERAGE_COUNT";
export const STORIES = "STORIES";
export const PROMPTS = "PROMPTS";

export const TOP_STORIES = "TOP_STORIES";
export const FAVOURITES = "FAVOURITES";
export const FAVOURITES_LIST = "FAVOURITES_LIST";

export const TIME_SPENT_IN_APP = "TIME_SPENT_IN_APP";
export const STORIES_READ = "STORIES_READ";
export const PROMPTS_READ = "PROMPTS_READ";
export const TOP_STORIES_TABLE = "TOP_STORIES_TABLE";
export const FAVOURITE_STORIES_READ_TABLE = "FAVOURITE_STORIES_READ_TABLE";

// Filter
export const DATA_TYPE = "DATA_TYPE";
export const FILTER_ORG_CODE_TYPE = "FILTER_ORG_CODE_TYPE";
export const FILTER_AGE_TYPE = "FILTER_AGE_TYPE";
export const FILTER_DATE_TYPE = "FILTER_DATE_TYPE";

export const UPDATE_ORG_CODE = "UPDATE_ORG_CODE";
export const UPDATE_PWD = "UPDATE_PWD";

// profile Updation
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_VALIDATE = "UPDATE_PROFILE_VALIDATE";
export const CLOSE_PROFILE_MESSAGE = "CLOSE_PROFILE_MESSAGE";

// Genral Action
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

// Pagination
export const PAGINATION = "PAGINATION";
export const NEXT_PAGE = "CURRENT_PAGE";
export const PER_PAGE = "PER_PAGE";

//Get Goal
export const GET_ALL_GOALS = "GET_ALL_GOALS";
