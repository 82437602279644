import React, { Component } from "react";
import { Dropdown, Figure } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { Trans } from "react-i18next";
import { connectMenu } from "react-contextmenu";
import { connect } from "react-redux";
import * as actions from "../Actions/index";

const Rac_logo = require("../../assets/images/rac_logo.png");
const Rac_logo_colapse = require("../../assets/images/rac_logo_collapse.png");

class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  toggleOnClickOutside() {
    document.querySelector(".right-sidebar").classList.remove("open");
  }

  render() {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/">
            <img
              src={Rac_logo}
              alt="logo"
              height={"auto"}
              width={"auto"}
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                zoom: "90%",
                marginLeft: 0,
              }}
            />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img
              src={Rac_logo_colapse}
              alt="logo"
              height={"auto"}
              width={"auto"}
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                zoom: "150%",
                marginLeft: 1,
              }}
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          {/* <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu "></span>
          </button> */}

          <ul className="navbar-nav navbar-nav-right">
            <li className="hidden-mobile">
              <div className="w-auto m-auto d-flex justify-content-center align-items-center pt-2">
                <h2 className="text-ask display-5 text-center font-weight-bold">
                  Data Dashboard for Program Managers
                </h2>
              </div>
            </li>
            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link">
                  <div className="nav-profile-img">
                    {/* <img src="" alt="user" /> */}
                    <Figure>
                      <Figure.Image
                        width={10}
                        height={10}
                        alt={"sd"}
                        src={require("../../assets/images/auth/lockscreen-bg.jpg")}
                        style={{ borderRadius: "100px" }}
                      />
                    </Figure>
                    <span className="availability-status online"></span>
                  </div>
                  <div className="nav-profile-text">
                    <p className="mb-1 text-dark">
                      {localStorage.getItem("user")
                        ? localStorage.getItem("user").toString().slice(1, -1)
                        : ""}
                    </p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item
                    href="!#"
                    onClick={(evt) => {
                      evt.preventDefault();
                    }}
                  >
                    <Link
                      className="text-decoration-none text-reset"
                      to={"/auth/changepassword"}
                    >
                      <i className="mdi mdi-cached mr-2 text-success"></i>
                      Change Password
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="!#"
                    onClick={(e) => {
                      // this.props.logout();
                      actions.logout();
                    }}
                  >
                    <i className="mdi mdi-power mr-2 text-primary"></i>
                    <Trans>Signout</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            {/* <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="mdi mdi-email-outline"></i>
                  <span className="count-symbol bg-warning"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <h6 className="p-3 mb-0">
                    <Trans>Messages</Trans>
                  </h6>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <img
                        src={require("../../assets/images/faces/face4.jpg")}
                        alt="user"
                        className="profile-pic"
                      />
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                        <Trans>Mark send you a message</Trans>
                      </h6>
                      <p className="text-gray mb-0">
                        1 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <img
                        src={require("../../assets/images/faces/face2.jpg")}
                        alt="user"
                        className="profile-pic"
                      />
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                        <Trans>Cregh send you a message</Trans>
                      </h6>
                      <p className="text-gray mb-0">
                        15 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <img
                        src={require("../../assets/images/faces/face3.jpg")}
                        alt="user"
                        className="profile-pic"
                      />
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                        <Trans>Profile picture updated</Trans>
                      </h6>
                      <p className="text-gray mb-0">
                        18 <Trans>Minutes ago</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <h6 className="p-3 mb-0 text-center cursor-pointer">
                    4 <Trans>new messages</Trans>
                  </h6>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/* <li className="nav-item">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <i className="mdi mdi-bell-outline"></i>
                  <span className="count-symbol bg-danger"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <h6 className="p-3 mb-0">
                    <Trans>Notifications</Trans>
                  </h6>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="mdi mdi-calendar"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        <Trans>Event today</Trans>
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        <Trans>
                          Just a reminder that you have an event today
                        </Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="mdi mdi-settings"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        <Trans>Settings</Trans>
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        <Trans>Update dashboard</Trans>
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item
                    className="dropdown-item preview-item"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="mdi mdi-link-variant"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        <Trans>Launch Admin</Trans>
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        <Trans>New admin wow</Trans>!
                      </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <h6 className="p-3 mb-0 text-center cursor-pointer">
                    <Trans>See all notifications</Trans>
                  </h6>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/*   */}
            {/* Side Filters */}
            {/* <li className="nav-item nav-settings">
              <button
                type="button"
                className="nav-link border-0"
                onClick={this.toggleRightSidebar}
              >
                <i className="mdi mdi-format-line-spacing"></i>
                <span className="mx-2 mr-5 fs-1">Filter Results</span>
              </button>
            </li> */}
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center "
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu text-RACPrimary"></span>
          </button>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.Profile.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(actions.logout());
    },
    updateProfile: (data) => {
      dispatch(actions.updateProfile(data));
    },
    closeProfleMsg: () => {
      dispatch(actions.closeProfleMsg());
    },
  };
};
export default connectMenu(mapStateToProps, mapDispatchToProps)(Navbar);
