import { PROMPTS } from "../Actions/types";
const INITIAL_STATE = {
  prompts_count: {},
};
const PromptsCountReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROMPTS:
      return { ...state, ...INITIAL_STATE, prompts_count: action.data };
    default:
      return state;
  }
};

export default PromptsCountReducers;
