import { TOP_STORIES_TABLE } from "../Actions/types";
const INITIAL_STATE = {
  top_stories_table: {},
};
const GetTopStoriesTable = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOP_STORIES_TABLE:
      return { ...state, ...INITIAL_STATE, top_stories_table: action.data };
    default:
      return state;
  }
};

export default GetTopStoriesTable;
