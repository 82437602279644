import React, { Component } from "react";

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright ©{" "}
            <a
              href="https://readaskchat.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="font-weight-bold">readaskchat.net</span>
            </a>{" "}
            {currentYear}
          </span>
          {/* <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            Team <span className="font-weight-bold">Whizzbridge</span>.
          </span> */}
        </div>
      </footer>
    );
  }
}

export default Footer;
